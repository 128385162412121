const host = process.env.NEXT_PUBLIC_HOST
const defAlt = 'Samochody'
const defTitle = 'Ogłoszenia motoryzacyjne - Samochody i auta'
const logos = {
    light: {
        src: '/logo/logo-white.svg',
    },

    dark: {
        src: '/logo/logo-blue.svg',
    }
}

const Logo = ({
    bg = 'light',
    className = '',
    height = 54,
    src = '',
    srcDir = '/logo/',
    width = 184,
}) => {

    const data = (logos[bg] || {})
    const { alt = defAlt, title = defTitle} = data

    src = host + (src
        ? `${srcDir}${src}`
        : data.src)

    return src
        ? <img width={width} height={height} src={src} alt={alt} title={title} className={className} />
        : null
}

export default Logo