import { memo, useMemo, useState } from 'react'
import Chat from '@components/chat/nav/Icon'
import Button from '@components/button/Button'
import ListOut from '@components/shared/ListOut'
import NavLink from '@components/shared/NavLink'
import useT from '@hooks/useTranslation'

import {
    USER_MY_DATA,
    USER_MY_OFFERS,
    USER_MY_BUY_OFFERS,
    USER_MY_PRODUCTS,
    USER_MY_ARTICLES,
    USER_SUBSCRIPTIONS,
    USER_DEALER,
    USER_PARTNER,
    USER_WALLET,
    LOGOUT_PAGE,
} from '@constants/routes'

import styles from 'styles/user/UserNav.module.css'

const HOST = process.env.NEXT_PUBLIC_HOST

const isDealer = (user) => Boolean(user.dealer && !user.is_partner)
const isPartner = (user) => Boolean(user.is_partner)
const isNotPartner = (user) => !isPartner(user)
const isDealerOrPartner = (user) => isPartner(user) || isDealer(user)

const links = [
    { t: 'Moje dane', to: HOST + USER_MY_DATA },

    { t: 'Moje ogłoszenia', to: HOST + USER_MY_OFFERS, condition: isNotPartner },
    { t: 'Moje oferty kupna', to: HOST + USER_MY_BUY_OFFERS, condition: isNotPartner },

    { t: 'Moje produkty', to: HOST + USER_MY_PRODUCTS, condition: isPartner },
    { t: 'Moje artykuły', to: HOST + USER_MY_ARTICLES, condition: isPartner },

    { t: 'Subskrypcje', to: HOST + USER_SUBSCRIPTIONS, condition: isDealerOrPartner },

    { t: 'Wizytówka', to: HOST + USER_DEALER, condition: isDealer },
    { t: 'Konto partnera', to: HOST + USER_PARTNER, condition: isPartner },

    { t: 'Mój portfel', to: HOST + USER_WALLET },
]

const logout = { t: 'Wyloguj', to: HOST + LOGOUT_PAGE }

const buttonStyle = { paddingInline: 10 }

const UserMenuMemoized = memo(UserMenu, (oldProps, newProps) => {
    return oldProps.user?._id === newProps.user?._id
        && oldProps.mobile === newProps.mobile
})

const UserNav = ({ alwaysOpen, user, mobile, nologout }) => {
    const [open, setOpen] = useState(alwaysOpen)
    const toggle = () => setOpen((open) => !open)
    const text = useT('Moje konto')
    return (
        <>
            {
                mobile
                    ? <span className={styles.account} onClick={toggle} role="button">
                        {text}
                      </span>

                    : <Button
                        icon="perm_identity"
                        onClick={toggle}
                        style={buttonStyle}
                        teal
                      >
                        {text}&nbsp; { !alwaysOpen && '▾' }
                      </Button>
            }

            { (open || alwaysOpen) &&
                <UserMenuMemoized mobile={mobile} user={user} nologout={nologout} /> }
        </>
    )
}


function UserMenu ({ mobile, user, nologout }) {
    const userLinks = useMemo(() => getUserLinks(user, links), [user])
    return mobile
        ? <div className={styles.mobile}>
            <ListOut id="t" items={userLinks.slice(0, 3)} Component={NavLink} />
            <Chat t="Wiadomości" />
            <ListOut id="t" items={userLinks.slice(3)} Component={NavLink} />
            { !nologout && <UserSessionNavLogout mobile={mobile} user={user} /> }
          </div>

        : <div className={styles.menu}>
            <ListOut id="t" items={userLinks} Component={NavLink} />
            { !nologout && <UserSessionNavLogout mobile={mobile} user={user} /> }
          </div>
}

function UserSessionNavLogout ({ mobile, user }) {
    if (!user) return null
    return <ListOut id="t" items={[logout]} Component={NavLink} style={mobile ? {color:'var(--teal)'} : undefined} />
}

function getUserLinks (user, links) {
    return links.reduce((acc, _link) => {
        const { condition, ...link } = _link
        return (condition && !condition(user)
            ? acc
            : acc.concat(link))
    }, [])
}

function checkIfPropsEqual (oldProps, newProps) {
    return (
           oldProps.alwaysOpen === newProps.alwaysOpen
        && oldProps.user === newProps.user
        && oldProps.mobile === newProps.mobile
    )
}

export default memo(UserNav, checkIfPropsEqual)
export {
    UserSessionNavLogout,
}