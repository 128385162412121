import Link from 'next/link'
import Icon from '@components/shared/Icon'
// import Notify from '@components/links/notify/Notify'
import {
    USER_WATCHED,
    // USER_WATCHED_CONFIGURATIONS,
    // USER_SUBSCRIPTIONS_RECIEVED_MESSAGES,
} from '@constants/routes'
import useT from '@hooks/useTranslation'

// import withWatchedNotifications from '@components/links/hoc/withWatchedNotifications'

import styles from './Watched.module.css'

function Watched ({ text }) { // { newConfigs, newSubscriptionMessages }
    return (
        <Link
            className={styles.link}
            href={USER_WATCHED}
            title="Obserwowane"
        >
                <>
                    {text ? useT('Obserwowane') : ''} <Icon>thumb_up</Icon>
                    {/* <Notify
                        count={newConfigs}
                        link={USER_WATCHED_CONFIGURATIONS}
                        icon="youtube_searched_for"
                        left />
                    <Notify
                        count={newSubscriptionMessages}
                        link={USER_SUBSCRIPTIONS_RECIEVED_MESSAGES}
                        icon="rss_feed"
                        right /> */}
                </>
            </Link>
    );
}

export default Watched // withWatchedNotifications()