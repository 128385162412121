import Icon from '@components/shared/Icon'
import { useChatUnreadMessages, useToggleChat } from '@components/chat/context/ChatStateContext'
import withUser from '@hoc/withUser'
import useT from '@hooks/useTranslation'

import styles from '@components/chat/styles/Icon.module.css'

const ChatIcon = ({ t }) => {
    const unreadMessageCount = useChatUnreadMessages()
    const hasUnreadMessages = Boolean(unreadMessageCount)

    const toggleChat = useToggleChat()
    const icon = <aside className={styles.icon} onClick={toggleChat}>
        <div className={styles.inner}>
            <Icon>chat_bubble_outline</Icon>
            { hasUnreadMessages &&
                <span>{unreadMessageCount}</span> }
        </div>
    </aside>

    return t
        ? <span className={styles.wrapper} onClick={toggleChat}>
            <b>{ useT(t) }</b>
            { icon }
          </span>

        : icon
}

const Guard = ({ isLoggedIn, t }) =>
    isLoggedIn
        ? <ChatIcon t={t} />
        : null

export default withUser(Guard)
