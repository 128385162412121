"use client"

import { useState } from 'react'
import Section from './Section'
import Article from './Article'
import Button from '@components/button/Button'
import useT from '@hooks/useTranslation'

export default function ArticleSection (props) {
    const [readAll, setReadAll] = useState(false)
    const t = useT('Czytaj więcej')

    const { article, ...rest } = props
    const [firstPart, secondPart] = article.split('----')
    const onClick = () => setReadAll(true)

    return <Section>
        <Article {...{ article: firstPart, ...rest }} />
        { secondPart && <>
            { !readAll && <Button onClick={onClick} blue>{t}</Button> }

            <div style={readAll ? undefined : { display: 'none' }}>
                <Article {...{ article: secondPart, ...rest }} />
           </div>
        </> }
    </Section>
}
