import { useCallback, useEffect, useState } from 'react'

const DIRECTIONS = {
    down: 0,
    up: 1,
}

const useWindowScrollDirection = (ignoreTreshold = 80, precision = 99) => {
    const [lastY, setLastY] = useState(0)
    const [direction, setDirection] = useState(1)

    const setScroll = useCallback((_) => {
        const newY = window.scrollY

        if (newY > lastY && direction != DIRECTIONS.down) {
            setDirection(DIRECTIONS.down)

        } else if (newY < lastY && direction != DIRECTIONS.up) {
            setDirection(DIRECTIONS.up)
        }

        if (newY < ignoreTreshold || Math.abs(newY - lastY) >= precision) {
            setLastY(newY)
        }

    }, [direction, lastY, precision, setDirection, setLastY])

    useEffect(() => {
        setScroll()
        window.addEventListener('scroll', setScroll)

        return () => window.removeEventListener('scroll', setScroll)
    }, [setScroll])

    return [lastY <= ignoreTreshold ? DIRECTIONS.up : direction]
}

useWindowScrollDirection.DIRECTIONS = DIRECTIONS

export default useWindowScrollDirection