import Link from 'next/link'
import useT from '@hooks/useTranslation'
import mix from '@utils/styles/mix'

import styles from 'styles/NavLinkColor.module.css'

const NavLink = ({ t, to, color, iconRight, iconLeft, half, grow, ...rest }) => (
    (<Link
    href={to}
    prefetch={false}
    className={mix([
        styles.navlink,
        styles[color],
        half && styles.half,
        grow && styles.grow])}
    data-color="true"
    data-button="true"
    {...rest}
    >
        <>
            { iconLeft }
            { useT(t) }
            { iconRight }
        </>
    </Link>)
)

export default NavLink