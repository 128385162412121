import Link from 'next/link'
import { useRouter } from 'next/navigation'
import useT from '@hooks/useTranslation'
import mix from '@utils/styles/mix'

import styles from 'styles/NavLink.module.css'

const NavLink = ({ t, to, ...rest }) => {
    const router = useRouter()

    const isActive = router.pathname == to
    const className = mix([styles.navlink, isActive && styles.active])

    return (
        <Link href={to} prefetch={false} className={className} {...rest}>
            { useT(t) }
        </Link>
    );
}

export default NavLink