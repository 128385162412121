import Portal from '@components/modal/Portal'
import {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import mix from '@utils/styles/mix'

import styles from 'styles/Hamburger.module.css'

let timeout

const useHamburger = (lockNavbar, deps = []) => {
	const [open, setOpen] = useState()
	const ref = useRef(null)

	const toggleOpen = useCallback(() => {
		clearTimeout(timeout)
		const navbar = document.getElementById('main-navb')
		ref.current.classList.toggle(styles.active)

		if (navbar && !open) navbar.style.zIndex = 51
		timeout = setTimeout(() => {
			setOpen(!open)
			lockNavbar(!open)
			if (navbar && open) navbar.style.zIndex = 21
		}, 300)
	}, [open, lockNavbar])

	useEffect(() => {
		return () => clearTimeout(timeout)
	}, [])

	const Hamburger = useMemo(() => {
		function Hamburger() {
			return (
				<button
					aria-label="Rozwiń menu mobilne"
					className={mix([
						styles.hamburger,
						styles.spin,
						open && styles.active,
					])}
					onClick={toggleOpen}
					ref={ref}
					type="button"
				>
					<span aria-hidden="true" className={styles.box}>
						<span className={styles.inner}></span>
					</span>
				</button>
			)
		}

		Hamburger.Menu = function Menu({ children }) {
			const hasStatus = typeof open === 'boolean'
			return (
				<Portal>
					<div
						className={mix([
							styles.menu,
							hasStatus && (open ? styles.open : styles.hidden),
						])}
					>
						{children}
					</div>
					<div
						className={mix([
							styles.overlay,
							hasStatus && (open ? styles.open : styles.hidden),
						])}
						onClick={toggleOpen}
					/>
				</Portal>
			)
		}

		return Hamburger
	}, [...deps, open, toggleOpen])

	return Hamburger
}

export default useHamburger
